<template>
  <div
    v-if="show"
    ref="pendingFeesModal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="pendingFeesModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital">
              Pending Fee
            </span>
            <a
              data-dismiss="#pendingFeesModal"
              class="
                modal__close modal__close--white
                d-flex
                align-items-center
                justify-content-between
              "
              @click="$emit('close')"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
        </div>
        <div class="modal__content">
          <div class="modal__body">
            <div class="text--center">
              <img src="@/assets/img/info.svg" alt="" srcset="" />
              <p
                class="
                  text--primary2 text--capital text--regular
                  my-3
                  text--600
                "
              >
                No Predefined Fee Available
              </p>
              <p class="text--black text--regular-alt text--500">
                No predefined Fees available for this account type, please
                define a Fee for this product first.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "noApplyFee",
  props: {
    show: Boolean,
    close: Function,
  },
};
</script>

<style scoped>
.text--600 {
  font-family: Montserrat-semibold !important;
}
.text--primary2 {
  color: #f4b740 !important;
}
.text--regular,
.text--regular-alt {
  font-size: 1.4rem !important;
}
</style>
